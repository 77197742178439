<template>
  <header>
    <div class="content">
      <!-- logo -->
      <div class="l">
        <div class="logo" v-show="baseData.logo">
          <img :src="baseData.logo" alt="logo" />
        </div>
        <h1>{{ baseData.systemTitle }}</h1>
        <!-- 导航 -->
        <div class="meun">
          <div
            v-for="item in meun"
            :key="item.index"
            class="meun_item"
            :class="meunCurrentIndex == item.index ? 'meun_item_active' : ''"
            @click="changMeunCurrentIndex(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div
        v-if="user"
        class="r"
        @mousemove="
          () => {
            showUserMeun = true;
          }
        "
        @mouseout="
          () => {
            showUserMeun = false;
          }
        "
      >
        <img src="@/assets/icon／用户2.png" alt class="user_avatar" />
        <span class="username">{{ user.nickname }}</span>
        <div class="user_action" v-show="showUserMeun">
          <div class="bangding" @click="goToPurchase">礼品采购</div>
          <div class="logout" @click="handleLogout">退出登录</div>
        </div>
      </div>

      <div class="r" v-else>
        <div
          class="login"
          @click="
            () => {
              $router.push({ name: 'login', query: { ...query } });
            }
          "
        >
          登录
        </div>
        <div class="register" @click="goRegister">
          免费注册
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    user: {
      type: Object
    },
    meunCurrentIndex: {
      type: Number
    }
  },
  data() {
    return {
      baseData: baseData,
      meun: [
        { index: 0, label: "首页", path: "/index" },
        { index: 1, label: "礼品商城", path: "/goods/purchase" },
        {
          index: 2,
          label: "操作手册",
          path:
            "https://alidocs.dingtalk.com/i/nodes/X6GRezwJlYLmjZBKFGlGwRMy8dqbropQ?utm_scene=team_space",
          isOpen: true
        },
        // { index: 3, label: '分站开通', path: '/join' },
        { index: 4, label: "系统公告", path: "/systemNotice" }
        /*{ index: 4, label: '店铺流量提升' }*/
      ],
      showUserMeun: false,
      query: {}
    };
  },
  methods: {
    changMeunCurrentIndex(item) {
      if (item.isOpen) {
        window.open(item.path);
      } else {
        this.$emit("changeMenuIndex", item);
      }
    },
    goToPurchase() {
      this.$emit("goToPurchase");
    },
    handleLogout() {
      this.$emit("handleLogout");
    },
    goRegister() {
      this.$emit("goRegister");
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
}
// 头部
header {
  width: 100%;
  height: 100px;
  background: #ffffff;

  .content {
    width: 1200px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    .l {
      display: flex;
      align-items: center;
      .logo {
        img {
          display: block;
          width: 96px;
          height: 96px;
          margin-right: 8px;
          background: #fff;
          // object-fit: cover;
        }
      }

      h1 {
        font-size: 34px;
        font-weight: 500;
        line-height: 64px;
        margin: 0;
        color: #000000;
        margin-right: 68px;
      }
      .meun {
        display: flex;
        align-items: center;
        cursor: pointer;
        .meun_item {
          margin-right: 32px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(54, 38, 38, 0.85);
        }
        .meun_item:hover {
          color: #fb3434;
        }
        .meun_item_active {
          color: #fb3434;
        }
      }
    }

    .r {
      height: 100px;
      display: flex;
      align-items: center;
      font-size: 16px;
      position: relative;
      .login {
        cursor: pointer;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        margin-right: 32px;
      }
      .register {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 112px;
        height: 36px;
        background: rgba(251, 52, 52, 1);
        color: #fff;
        border-radius: 4px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .username {
        font-size: 16px;
        font-weight: 500;
        color: rgba(251, 52, 52, 1);
      }
      .user_action {
        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        width: 150px;
        height: 88px;
        position: absolute;
        bottom: -60px;
        left: -80px;
        z-index: 10000;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        cursor: pointer;
        div {
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }
}
</style>
