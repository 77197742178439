<template>
  <div class="main">
    <header-box
      @changeMenuIndex="changeMenuIndex"
      :user="user"
      :meunCurrentIndex="meunCurrentIndex"
      @handleLogout="handleLogout"
      @goToPurchase="goToPurchase"
      @goRegister="goRegister"
    ></header-box>

    <!--    <section class="banner">-->
    <!--      <div class="content">-->
    <!--        <div class="join_title">分站开通·拥有属于自己的网站</div>-->
    <!--        <div class="sub_title">-->
    <!--          网站完全独立控制，管理操作简单；全天24小时自动售单，无需人工干预。-->
    <!--        </div>-->
    <!--        <div class="consult_btn" @click="openConcat">立即咨询</div>-->
    <!--        <div class="advantage">-->
    <!--          <div class="advantage_item">-->
    <!--            <div class="advantage_item_icon">-->
    <!--              <img src="@/assets/icon／成本低.png" alt />-->
    <!--            </div>-->
    <!--            <div class="advantage_item_text">成本低</div>-->
    <!--          </div>-->
    <!--          <div class="advantage_item">-->
    <!--            <div class="advantage_item_icon">-->
    <!--              <img src="@/assets/icon／建站快.png" alt />-->
    <!--            </div>-->
    <!--            <div class="advantage_item_text">建站快</div>-->
    <!--          </div>-->
    <!--          <div class="advantage_item">-->
    <!--            <div class="advantage_item_icon">-->
    <!--              <img src="@/assets/icon／上线快.png" alt />-->
    <!--            </div>-->
    <!--            <div class="advantage_item_text">上线快</div>-->
    <!--          </div>-->
    <!--          <div class="advantage_item">-->
    <!--            <div class="advantage_item_icon">-->
    <!--              <img src="@/assets/icon／维护快.png" alt />-->
    <!--            </div>-->
    <!--            <div class="advantage_item_text">维护快</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <div class="product_advantage">
      <div class="content">
        <div class="product_advantage_title">产品优势</div>
        <div class="product_advantage_body">
          <div class="product_advantage_item">
            <div class="item_icon">
              <img src="@/assets/icon／技术支持.png" alt />
            </div>
            <div class="item_title">
              <div class="item_title_big">提供技术支持</div>
              <div class="item_title_sub">24小时技术在线维护</div>
            </div>
          </div>
          <div class="product_advantage_item">
            <div class="item_icon">
              <img src="@/assets/icon／独立域名.png" alt />
            </div>
            <div class="item_title">
              <div class="item_title_big">独立域名</div>
              <div class="item_title_sub">独立后台 自由管理</div>
            </div>
          </div>
          <div class="product_advantage_item">
            <div class="item_icon">
              <img src="@/assets/icon／分站成本低.png" alt />
            </div>
            <div class="item_title">
              <div class="item_title_big">分站成本低</div>
              <div class="item_title_sub">提供服务器、域名 维护、升级服务</div>
            </div>
          </div>
          <div class="product_advantage_item">
            <div class="item_icon">
              <img src="@/assets/icon／建站速度快.png" alt />
            </div>
            <div class="item_title">
              <div class="item_title_big">建站速度快</div>
              <div class="item_title_sub">10分钟即可实现建站 推广运营网站</div>
            </div>
          </div>
          <div class="product_advantage_item">
            <div class="item_icon">
              <img src="@/assets/icon／API接口.png" alt />
            </div>
            <div class="item_title">
              <div class="item_title_big">网站API接口</div>
              <div class="item_title_sub">免费提供 无需任何费用</div>
            </div>
          </div>
          <div class="product_advantage_item">
            <div class="item_icon">
              <img src="@/assets/icon／安全稳定.png" alt />
            </div>
            <div class="item_title">
              <div class="item_title_big">提供稳定安全</div>
              <div class="item_title_sub">低价的快递费 礼品货源</div>
            </div>
          </div>
          <div class="product_advantage_item">
            <div class="item_icon">
              <img src="@/assets/icon／账户.png" alt />
            </div>
            <div class="item_title">
              <div class="item_title_big">利润直达账户</div>
              <div class="item_title_sub">提现 24小时内到账</div>
            </div>
          </div>
          <div class="product_advantage_item">
            <div class="item_icon">
              <img src="@/assets/icon／前景可观.png" alt />
            </div>
            <div class="item_title">
              <div class="item_title_big">前景可观</div>
              <div class="item_title_sub">客源稳定 收入稳定</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="join_step_wrapper">-->
    <!--      <div class="content">-->
    <!--        <div class="join_step_title">分站开通仅需6步，高效省心</div>-->
    <!--        <div class="join_step_body">-->
    <!--          <div class="join_step">-->
    <!--            <div class="join_step_item">-->
    <!--              <div class="join_step_item_title">-->
    <!--                <span class="num">1</span>-->
    <!--                <span class="text">注册账号</span>-->
    <!--              </div>-->
    <!--              <div class="join_step_item_body">-->
    <!--                注册会员账号，若已有账号，直接在登录界面进行登录-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="join_step_jiantou">-->
    <!--              <img src="@/assets/iocn／箭头.png" alt />-->
    <!--            </div>-->
    <!--            <div class="join_step_item">-->
    <!--              <div class="join_step_item_title">-->
    <!--                <span class="num">2</span>-->
    <!--                <span class="text">申请代理</span>-->
    <!--              </div>-->
    <!--              <div class="join_step_item_body">-->
    <!--                进入礼品商城，在“网站管理”点击“开通”申请分站-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="join_step_jiantou">-->
    <!--              <img src="@/assets/iocn／箭头.png" alt />-->
    <!--            </div>-->
    <!--            <div class="join_step_item">-->
    <!--              <div class="join_step_item_title">-->
    <!--                <span class="num">3</span>-->
    <!--                <span class="text">支付费用</span>-->
    <!--              </div>-->
    <!--              <div class="join_step_item_body">按照操作流程，点击付费</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="join_step_two">-->
    <!--            <div class="join_step_jiantou">-->
    <!--              <img src="@/assets/iocn／箭头 (1).png" alt />-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="join_step">-->
    <!--            <div class="join_step_item">-->
    <!--              <div class="join_step_item_title">-->
    <!--                <span class="num">6</span>-->
    <!--                <span class="text">推广盈利</span>-->
    <!--              </div>-->
    <!--              <div class="join_step_item_body">-->
    <!--                对已上线的分站进行运营管理、推广管理-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="join_step_jiantou">-->
    <!--              <img src="@/assets/iocn／箭头 (2).png" alt />-->
    <!--            </div>-->
    <!--            <div class="join_step_item">-->
    <!--              <div class="join_step_item_title">-->
    <!--                <span class="num">5</span>-->
    <!--                <span class="text">开通成功</span>-->
    <!--              </div>-->
    <!--              <div class="join_step_item_body">-->
    <!--                系统审核通过后，分站即开通成功，可立即投入使用-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="join_step_jiantou">-->
    <!--              <img src="@/assets/iocn／箭头 (2).png" alt />-->
    <!--            </div>-->
    <!--            <div class="join_step_item">-->
    <!--              <div class="join_step_item_title">-->
    <!--                <span class="num">4</span>-->
    <!--                <span class="text">网站配置</span>-->
    <!--              </div>-->
    <!--              <div class="join_step_item_body">-->
    <!--                设置网站域名、网站名称、推广佣金，QQ号等信息，完成后点击“保存”,等待系统审核-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="join_step_buttom">-->
    <!--          <div-->
    <!--            class="join_step_btn"-->
    <!--            @click="-->
    <!--              () => {-->
    <!--                $router.push('/oem/manage');-->
    <!--              }-->
    <!--            "-->
    <!--          >-->
    <!--            立即开通分站-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="profit_model">-->
    <!--      <div class="content">-->
    <!--        <div class="profit_model_title">盈利模式</div>-->
    <!--        <div class="profit_model_step">-->
    <!--          <div class="profit_model_step_item">-->
    <!--            <div class="profit_model_step_item_icon">-->
    <!--              <img src="@/assets/img／1.png" alt />-->
    <!--            </div>-->
    <!--            <div class="profit_model_step_item_body">注册会员并开通分站</div>-->
    <!--          </div>-->
    <!--          <div class="profit_model_step_jiantou">-->
    <!--            <img src="@/assets/img／箭头(1).png" alt />-->
    <!--          </div>-->
    <!--          <div class="profit_model_step_item">-->
    <!--            <div class="profit_model_step_item_icon">-->
    <!--              <img src="@/assets/img／2.png" alt />-->
    <!--            </div>-->
    <!--            <div class="profit_model_step_item_body">-->
    <!--              邀请好友成功下单、开站-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="profit_model_step_jiantou">-->
    <!--            <img src="@/assets/img／箭头(1).png" alt />-->
    <!--          </div>-->
    <!--          <div class="profit_model_step_item">-->
    <!--            <div class="profit_model_step_item_icon">-->
    <!--              <img src="@/assets/img／3.png" alt />-->
    <!--            </div>-->
    <!--            <div class="profit_model_step_item_body">-->
    <!--              获得订单提成佣金、开站佣金-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="invite">-->
    <!--          <img src="@/assets/bg.png" alt />-->
    <!--          <div class="invite_body">-->
    <!--            <div class="invite_body_top">-->
    <!--              <div class="invite_example">-->
    <!--                <div class="invite_example_l">-->
    <!--                  <img src="@/assets/img／被邀请人.png" alt />-->
    <!--                  <div class="name">被邀请人</div>-->
    <!--                  <div class="invite_text">-->
    <!--                    注册账号，成功-->
    <!--                    <span class="red">开通B分站</span>-->
    <!--                  </div>-->
    <!--                  <div class="invite_text">-->
    <!--                    并且完成-->
    <!--                    <span class="red">N笔订单</span>交易-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="invite_example_jiantou">-->
    <!--                  <div class="jiantou_text">举个例子</div>-->
    <!--                  <img src="@/assets/img／箭头(1).png" alt />-->
    <!--                </div>-->
    <!--                <div class="invite_example_r">-->
    <!--                  <img src="@/assets/img／邀请人.png" alt />-->
    <!--                  <div class="name">邀请人</div>-->
    <!--                  <div class="invite_text">-->
    <!--                    A分站将获得-->
    <!--                    <span class="red">开站佣金</span>-->
    <!--                  </div>-->
    <!--                  <div class="invite_text">-->
    <!--                    以及N笔订单的-->
    <!--                    <span class="red">提成佣金</span>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="invite_body_bottom">-->
    <!--              <div>-->
    <!--                * 订单提成佣金=B分站成本-A分站成本（A分站可设置B分站成本）-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                * B分站再发展分站，开站和提成佣金由B分站获得，A分站无佣金；-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { goodsListAll, goodsPartitionList } from "@api";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { sipUser } from "@/api";
import HeaderBox from "@/components/HeaderBox/index";

export default {
  data() {
    return {
      meunCurrentIndex: 3,
      baseData: baseData,
      goods: [],
      partition: [],
      user: null,
      inviterCode: Vue.ls.get("DEFAULT_INVITE_KEY")
    };
  },
  created() {
    goodsListAll({ pageSize: 1000 }).then(res => {
      this.goods = res.data.list;
    });
    goodsPartitionList().then(res => {
      this.partition = res.data;
    });
  },
  mounted() {
    sipUser().then(res => {
      this.user = res.data;
    });
  },
  methods: {
    ...mapActions(["Logout"]),
    ...mapGetters(["userInfo"]),

    // 修改索引
    changeMenuIndex(item) {
      if (item.path.indexOf("static.wodh.cn") > -1) {
        window.open(item.path);
      } else {
        setTimeout(() => {
          this.$router.push({ path: item.path });
        }, 200);
        this.meunCurrentIndex = item.index;
      }
    },
    handleLogout() {
      const that = this;
      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        onOk() {
          return that
            .Logout({})
            .then(() => {
              // window.location.reload();
              window.location.href = "/index";
            })
            .catch(err => {
              that.$message.error({
                title: "错误",
                description: err.message
              });
            });
        },
        onCancel() {}
      });
    },
    openConcat() {
      window.open(
        "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97817c5390744c9abd4ae0e0344549e2a29e6fdc2fdf2b2137a1eaebae5c0f1ad81b0ab6a09db04a4b9520996e25ff7c"
      );
    },
    goRegister() {
      if (this.inviterCode) {
        this.$router.push({ name: "register", query: { c: this.inviterCode } });
      } else {
        this.$router.push({ name: "register" });
      }
    },
    goToPurchase() {
      this.$router.push({ path: "/goods/purchase", query: { ...this.query } });
    }
  },
  components: {
    HeaderBox
  }
};
</script>

<style lang="less" scoped>
.main {
  min-width: 1200px;
  box-sizing: border-box;
  background-color: #ffffff;
  position: relative;
  font-family: PingFang SC;
  .content {
    width: 1200px;
    margin: 0 auto;
  }

  .banner {
    height: 586px;
    background-image: url(~@/assets/bg／分站加盟.png);
    background-size: cover;

    position: relative;
    .content {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .join_title {
        margin-top: 98px;
        margin-bottom: 16px;
        font-size: 62px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }

      .sub_title {
        font-size: 15.994px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 48px;
      }

      .consult_btn {
        cursor: pointer;
        width: 186px;
        height: 40px;
        background: rgba(255, 255, 255, 1);
        border-radius: 28px;
        color: #fb3434;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 75px;
      }
      .advantage {
        position: absolute;
        // top: 386px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1200px;
        height: 176px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        display: flex;
        padding: 8.006px 0;

        .advantage_item {
          width: 160px;
          height: 160px;
          margin-left: 112px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advantage_item_icon {
            margin-top: 24px;
            img {
              width: 64px;
              height: 64px;
              margin-bottom: 16px;
            }
          }

          .advantage_item_text {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }
  .product_advantage {
    margin-bottom: 84px;

    .product_advantage_title {
      margin-top: 56px;
      margin-bottom: 40px;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }

    .product_advantage_body {
      display: flex;
      flex-wrap: wrap;
      .product_advantage_item {
        width: 282px;
        height: 205px;
        margin-right: 24px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(-n + 4) {
          margin-bottom: 24px;
        }
        .item_icon {
          margin-top: 24px;
          margin-bottom: 16px;
          img {
            width: 64px;
            height: 64px;
          }
        }

        .item_title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .item_title_big {
            margin-bottom: 8px;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
          }

          .item_title_sub {
            font-size: 14px;
            color: rgba(112, 112, 112, 1);
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  .join_step_wrapper {
    background-image: url(~@/assets/bg／分站加盟步骤.png);
    background-size: cover;
    .content {
      padding: 80px 0;
      .join_step_title {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 80px;
      }
      .join_step_body {
        .join_step {
          display: flex;
          justify-content: space-around;
          // align-items: center;
          .join_step_item {
            width: 306px;

            .join_step_item_title {
              margin-bottom: 18px;
              .num {
                transform: rotate(90deg);
                width: 20px;
                height: 40px;
                font-size: 34px;
                font-family: Roboto;
                font-weight: bold;
                color: rgba(255, 255, 255, 1);
                opacity: 1;
                margin-right: 8px;
              }
              .text {
                font-size: 20px;
                color: rgba(255, 255, 255, 1);
              }
            }

            .join_step_item_body {
              // width: 306.01px;
              // height: 110.995px;
              padding: 32px 40px;
              background: rgba(255, 255, 255, 1);
              border: 2px solid rgba(255, 255, 255, 1);
              border-radius: 16px;
              font-size: 16px;
              display: flex;
              align-items: center;
              color: rgba(0, 0, 0, 0.65);
            }
          }
          .join_step_jiantou {
            margin: 100px 8px 0;
          }
        }
        .join_step_two {
          margin: 19px 142px;

          .join_step_jiantou {
            text-align: right;
          }
        }
      }
      .join_step_buttom {
        display: flex;
        justify-content: center;
        .join_step_btn {
          cursor: pointer;
          width: 274px;
          height: 56px;
          background: rgba(255, 184, 19, 1);
          box-shadow: 2px 2px 20px rgba(255, 184, 19, 0.62);
          margin-top: 84px;
          border-radius: 28px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
        }
      }
    }
  }
  .profit_model {
    padding-bottom: 165px;
    .content {
      .profit_model_title {
        margin-top: 80px;
        margin-bottom: 60px;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
      }

      .profit_model_step {
        display: flex;
        justify-content: space-around;
        margin-bottom: 61px;
        .profit_model_step_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 320px;
          .profit_model_step_item_icon {
            img {
              width: 120px;
              height: 120px;
            }
          }

          .profit_model_step_item_body {
            color: rgba(251, 52, 52, 1);
            font-size: 24px;
            margin-top: 24px;
          }
        }

        .profit_model_step_jiantou {
          // margin-left: 92px;
          // margin-right: 132px;
          margin-top: 48px;
          img {
          }
        }
      }
      .invite {
        text-align: center;
        position: relative;
        .invite_body {
          position: absolute;
          top: 120px;
          left: 102px;
          bottom: 42px;
          right: 102px;

          .invite_body_top {
            .invite_example {
              display: flex;
              justify-content: space-between;
              .name {
                font-size: 24px;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 32px;
              }
              .invite_text {
                color: rgba(0, 0, 0, 0.65);
                font-size: 20px;
              }
              .red {
                color: #fb3434;
              }
              .invite_example_l {
                width: 384px;

                img {
                  display: inline-block;
                  width: 80px;
                  height: 80px;
                  margin-bottom: 8px;
                }
              }

              .invite_example_jiantou {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .jiantou_text {
                  font-weight: bold;
                  font-size: 24px;
                  color: rgba(251, 52, 52, 1);
                  margin-bottom: 36px;
                }
                img {
                  width: 64px;
                  height: 24px;
                }
              }

              .invite_example_r {
                width: 384px;
                img {
                  display: inline-block;
                  width: 80px;
                  height: 80px;
                  margin-bottom: 8px;
                }
              }
            }
          }
          .invite_body_bottom {
            margin-top: 32px;
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
